import { defineStore } from "pinia";

import {
  Annotation,
  AnnotationsType,
  Publication,
  ScreeningState,
  TextSelection,
  ManualAnnotationRequestInput,
  AnnotationFilterStatus,
} from "@/types/publications";
import { Tags } from "@/types/projects";
import { HttpReturn, useHttp } from "@/composables/useHttp";

export type PublicationsState = {
  publications: Publication[];
  selectedPublication: Publication | null;
  textSelection: TextSelection | null;
  selectedAnnotation: Annotation | null;
  publicationsLoading: boolean;
  annotationsLoading: boolean;
  annotationFilterStatus: AnnotationFilterStatus;
};

export const usePublicationsStore = defineStore("publications", {
  state: (): PublicationsState => ({
    publications: [],
    selectedPublication: null,
    textSelection: null,
    selectedAnnotation: null,
    publicationsLoading: false,
    annotationsLoading: false,
    annotationFilterStatus: "all",
  }),
  actions: {
    syncSelectedPublicationToAllPublications() {
      if (this.selectedPublication) {
        const updatedPublicationIndex = this.publications.findIndex(
          (publication) => publication.id === this.selectedPublication?.id
        );
        if (updatedPublicationIndex > -1) {
          this.publications[updatedPublicationIndex] = {
            ...this.selectedPublication,
          };
        }
      }
    },
    async fetchPublications({
      projectId,
      includePreviouslyScreened,
    }: {
      projectId: number;
      includePreviouslyScreened: boolean;
    }): Promise<Partial<HttpReturn>> {
      this.publicationsLoading = true;
      const { response, error } = await useHttp({
        method: "GET",
        url: `/Publication/ProjectPublications?projectId=${projectId}&includePreviouslyScreened=${includePreviouslyScreened.toString()}`,
      });
      if (response?.value?.data) {
        this.publications = response.value.data;
      }
      this.publicationsLoading = false;
      return { error };
    },
    async fetchPublication(id: number) {
      const { response } = await useHttp({
        method: "GET",
        url: `/Publication?id=${id}`,
      });
      if (response?.value?.data) {
        this.selectedPublication = response.value.data;
        this.syncSelectedPublicationToAllPublications();
      }
    },
    setSelectedPublication(publicationId: number): void {
      const selectedPublication = this.publications.find(
        (pub) => pub.id === publicationId
      );
      if (selectedPublication) {
        this.selectedPublication = selectedPublication;
        this.selectedAnnotation = null;
      }
    },
    async setInclusionDecision({
      publicationId,
      decision,
      inclusionDecisionType,
    }: {
      publicationId: number;
      decision: ScreeningState;
      inclusionDecisionType: 0 | 1;
    }): Promise<Partial<HttpReturn>> {
      const { response, error } = await useHttp({
        method: "POST",
        url: "/Publication/SetInclusionDecision",
        data: {
          publicationId,
          state: decision,
          inclusionDecisionType,
        },
      });
      if (response) {
        const updatedPublications = this.publications.map((pub) =>
          pub.id === response?.value?.data.id ? response.value.data : pub
        );
        this.publications = updatedPublications;
      }
      return { error };
    },
    async fetchAbstractAnnotations(
      publicationId: number
    ): Promise<Partial<HttpReturn>> {
      const { response, error } = await useHttp({
        method: "GET",
        url: `/Annotation/AbstractNERResults?publicationId=${publicationId}`,
      });
      if (response?.value?.data) {
        this.setAnnotations({
          annotations: response.value.data,
          annotationsType: "abstractAnnotations",
        });
        this.selectedAnnotation = null;
      }
      return { error };
    },
    async fetchTitleAnnotations(
      publicationId: number
    ): Promise<Partial<HttpReturn>> {
      const { response, error } = await useHttp({
        method: "GET",
        url: `/Annotation/TitleNERResults?publicationId=${publicationId}`,
      });
      if (response?.value?.data) {
        this.setAnnotations({
          annotations: response.value.data,
          annotationsType: "titleAnnotations",
        });
        this.selectedAnnotation = null;
      }
      return { error };
    },
    setAnnotations({
      annotations,
      annotationsType,
    }: {
      annotations: Annotation[];
      annotationsType: AnnotationsType;
    }) {
      if (this.selectedPublication) {
        this.selectedPublication = {
          ...this.selectedPublication,
          [annotationsType]: annotations,
        };
        this.syncSelectedPublicationToAllPublications();
      }
    },
    setUpdatedAnnotations({
      updatedAnnotation,
      annotationsType,
    }: {
      updatedAnnotation: Annotation;
      annotationsType: AnnotationsType;
    }) {
      const annotations =
        this.selectedPublication?.[annotationsType]?.filter(
          (annotation) => annotation.id !== updatedAnnotation.id
        ) || [];
      const newAnnotations = [...annotations, updatedAnnotation];
      this.setAnnotations({ annotations: newAnnotations, annotationsType });
      this.selectedAnnotation = updatedAnnotation;
      this.textSelection = null;
    },
    async approveAnnotation({
      annotation,
      annotationsType,
    }: {
      annotation: Annotation;
      annotationsType: AnnotationsType;
    }) {
      await useHttp({
        method: "PUT",
        url: `/Annotation/ApproveAutomaticAnnotation?annotationId=${annotation.id}`,
      });
      this.setUpdatedAnnotations({
        updatedAnnotation: {
          ...annotation,
          annotationStatus: 1,
        },
        annotationsType,
      });
    },
    async modifyAnnotation({
      annotationId,
      entity,
      entityColor,
      annotationsType,
    }: {
      annotationId: number;
      entity: string;
      entityColor: string;
      annotationsType: AnnotationsType;
    }) {
      const { response } = await useHttp({
        method: "PUT",
        url: `/Annotation/ModifyAnnotation?annotationId=${annotationId}&entity=${entity}&entityColor=${entityColor}`,
      });
      if (response.value?.data) {
        this.setUpdatedAnnotations({
          updatedAnnotation: response.value?.data,
          annotationsType,
        });
      }
    },
    setSelectedAnnotationById({
      id,
      annotationsType,
    }: {
      id: number;
      annotationsType: AnnotationsType;
    }): void {
      const selectedAnnotation = this.selectedPublication?.[
        annotationsType
      ]?.find((annotation) => annotation.id === id);
      if (selectedAnnotation && selectedAnnotation.annotationStatus === 0) {
        this.approveAnnotation({
          annotation: selectedAnnotation,
          annotationsType,
        });
      }
    },
    setSelectedAnnotationByStartPosition({
      startPosition,
      length,
      annotationsType,
    }: {
      startPosition: number;
      length: number;
      annotationsType: AnnotationsType;
    }): void {
      const selectedAnnotation = this.selectedPublication?.[
        annotationsType
      ]?.find(
        (annotation) =>
          annotation.startPosition === startPosition &&
          annotation.length === length
      );
      if (selectedAnnotation) {
        this.selectedAnnotation = selectedAnnotation;
        if (selectedAnnotation.annotationStatus === 0) {
          this.approveAnnotation({
            annotation: selectedAnnotation,
            annotationsType,
          });
        }
      }
    },
    async setNewAnnotation({
      payload,
      annotationsType,
    }: {
      payload: ManualAnnotationRequestInput;
      annotationsType: AnnotationsType;
    }): Promise<void> {
      const { response } = await useHttp({
        method: "POST",
        url:
          annotationsType === "abstractAnnotations"
            ? "/Annotation/AddAbstractAnnotation"
            : "/Annotation/AddTitleAnnotation",
        data: payload,
      });
      if (response?.value?.data) {
        this.setUpdatedAnnotations({
          updatedAnnotation: response.value.data,
          annotationsType,
        });
      }
    },
    async removeAnnotation({
      annotationId,
      annotationsType,
    }: {
      annotationId: number;
      annotationsType: AnnotationsType;
    }) {
      await useHttp({
        method: "DELETE",
        url: `/Annotation?annotationId=${annotationId}`,
      });
      const updatedAnnotations =
        this.selectedPublication?.[annotationsType]?.filter(
          (annotation) => annotation.id !== annotationId
        ) ?? [];
      this.selectedAnnotation = null;
      this.setAnnotations({
        annotations: updatedAnnotations,
        annotationsType,
      });
    },
    async acceptAllAnnotations(selectedPublication: Publication) {
      await useHttp({
        method: "PUT",
        url: `/Annotation/ApproveAllAutomaticAnnotations?publicationId=${selectedPublication?.id}`,
      });
      const updatedTitleAnnotations =
        selectedPublication.titleAnnotations?.map((annotation) => ({
          ...annotation,
          annotationStatus: 1 as 1 | 0,
        })) ?? [];
      this.setAnnotations({
        annotations: updatedTitleAnnotations,
        annotationsType: "titleAnnotations",
      });
      const updatedAbstractAnnotations =
        selectedPublication.abstractAnnotations?.map((annotation) => ({
          ...annotation,
          annotationStatus: 1 as 1 | 0,
        })) ?? [];
      this.setAnnotations({
        annotations: updatedAbstractAnnotations,
        annotationsType: "abstractAnnotations",
      });
    },
    setTags(tags: Tags) {
      if (this.selectedPublication) {
        this.selectedPublication = {
          ...this.selectedPublication,
          tags,
        };
        this.syncSelectedPublicationToAllPublications();
      }
    },
    async setTagSelection({
      tag,
    }: {
      tag: string;
    }): Promise<Partial<HttpReturn>> {
      const oldTags = this.selectedPublication?.tags ?? [];
      const newTags = oldTags.includes(tag)
        ? oldTags.filter((t) => t !== tag)
        : [...oldTags, tag];
      this.setTags(newTags);
      const { error, response } = await useHttp({
        method: "PUT",
        url: `/Publication/AddOrReplaceTags?publicationId=${this.selectedPublication?.id}`,
        data: newTags,
      });
      return { error, response };
    },
    async setComment({
      publicationId,
      text,
    }: {
      publicationId: number;
      text: string;
    }) {
      const { error, response } = await useHttp({
        method: "POST",
        url: "/Publication/AddOrReplaceComment",
        data: {
          publicationId,
          comment: text,
        },
      });
      if (response.value?.data) {
        if (this.selectedPublication) {
          this.selectedPublication = {
            ...this.selectedPublication,
            comment: response.value.data,
          };
        }
        this.syncSelectedPublicationToAllPublications();
      }
      return { error };
    },
    async setExclusionCriteria({
      publicationId,
      criterium,
    }: {
      publicationId: number;
      criterium: string | null;
    }): Promise<Partial<HttpReturn>> {
      let url = `/Publication/AddOrReplaceExclusionCriterium?publicationId=${publicationId}`;
      if (criterium) {
        url += `&exclusionCriterium=${criterium}`;
      }

      const { error, response } = await useHttp({
        method: "PUT",
        url: url,
      });
      if (!error.value?.message) {
        if (this.selectedPublication) {
          this.selectedPublication = {
            ...this.selectedPublication,
            exclusionCriterium: response.value?.data.exclusionCriterium,
          };
        }
        this.syncSelectedPublicationToAllPublications();
      }
      return { error };
    },
    async addExtractionText({
      projectId,
      publicationId,
      fieldId,
      extractionId,
      extractionText,
    }: {
      projectId: number;
      publicationId: number;
      fieldId: number;
      extractionId: number;
      extractionText: string;
    }): Promise<Partial<HttpReturn>> {
      const { error, response } = await useHttp({
        method: "POST",
        url: `/Publication/AddOrReplaceDataExtraction`,
        data: {
          projectId: projectId,
          publicationId: publicationId,
          fieldId: fieldId,
          id: extractionId,
          text: extractionText,
        },
      });

      return { error, response };
    },
  },
});
