
import { defineComponent } from "vue";
import Button from "@/components/UI/ButtonComponent.vue";
import { useConfirmationDialogStore } from "@/stores/confirmation-dialog";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "ConfirmationDialogComponent",
  components: { Button },

  setup() {
    const confirmationDialogStore = useConfirmationDialogStore();

    const onCancel = () => {
      confirmationDialogStore.hideConfirmationDialog();
    };

    const {
      isVisible,
      showCancelButton,
      title,
      message,
      callback,
      confirmationButtonText,
    } = storeToRefs(confirmationDialogStore);

    const onProceed = () => {
      if (callback.value) {
        callback.value();
      }
      confirmationDialogStore.hideConfirmationDialog();
    };

    return {
      onCancel,
      onProceed,
      showCancelButton,
      isVisible,
      title,
      message,
      confirmationButtonText,
    };
  },
});
