import { defineStore } from "pinia";

export type ErrorDialogState = {
  title: string;
  message?: string;
  isVisible: boolean;
};

export type ErrorDialogContent = {
  title: string;
  message?: string;
};

export const useErrorDialogStore = defineStore("dialog/error", {
  state: (): ErrorDialogState => ({
    title: "",
    isVisible: false,
    message: "",
  }),
  actions: {
    showErrorDialog(model: ErrorDialogContent): void {
      this.title = model.title;
      this.isVisible = true;
      this.message = model.message ?? "";
    },
    hideErrorDialog(): void {
      this.$reset();
    },
  },
});
