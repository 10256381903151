import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["h-12 flex items-center rounded py-4 px-6 w-max disabled:cursor-auto disabled:pointer-events-none", {
      'text-color-white bg-color-primary border-solid border-2 border-color-primary only:hover:bg-opacity-60 hover:border-opacity-60 disabled:bg-color-background-gray-medium disabled:border-color-background-gray-medium':
        _ctx.buttonStyle === 'primary',
      'text-color-primary bg-color-background-primary border-solid border-2 border-color-primary hover:bg-opacity-60 hover:border-opacity-60 disabled:text-color-black disabled:opacity-40 disabled:border-color-background-dark disabled:border-opacity-60':
        _ctx.buttonStyle === 'secondary',
      'txt-color-primary-dark bg-transparant underline hover:bg-color-background-light disabled:text-color-black disabled:opacity-40':
        _ctx.buttonStyle === 'tertiary',
    }])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}