import enGB from "./en-GB.json";
import { createI18n } from "vue-i18n";

export type MessageSchema = typeof enGB;

const TranslationOptions = {
  legacy: false,
  globalInjection: true,
  locale: "en-GB", // set locale
  fallbackLocale: "en-GB", // set fallback locale
  messages: {
    "en-GB": enGB,
  }, // set locale messagess
};
export const i18n = createI18n<[MessageSchema], "en-GB">(TranslationOptions);
