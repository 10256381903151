import { Directive } from "vue";

export const LoadingDirective: Directive = {
  mounted(el) {
    const loaderOverlay = document.createElement("div");
    loaderOverlay.id = `loaderOverlay-${el.id}`;
    const loader = document.createElement("div");
    el.style.position = "relative";
    loaderOverlay.style.display = "none";
    loaderOverlay.style.position = "absolute";
    loaderOverlay.style.left = "0";
    loaderOverlay.style.right = "0";
    loaderOverlay.style.top = "0";
    loaderOverlay.style.bottom = "0";
    loaderOverlay.style.background = "rgba(255,255,255,0.4)";
    loaderOverlay.style.zIndex = "2";
    loader.style.margin = "auto";
    loader.style.height = "100%";
    loader.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; height: 100%; max-height: 80vh; display: block;" width="48px" height="48px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<g transform="rotate(0 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#a8a8a8">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(30 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#a8a8a8">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(60 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#a8a8a8">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(90 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#a8a8a8">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(120 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#a8a8a8">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(150 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#a8a8a8">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(180 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#a8a8a8">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(210 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#a8a8a8">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(240 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#a8a8a8">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(270 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#a8a8a8">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(300 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#a8a8a8">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(330 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#a8a8a8">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
  </rect>
</g>
</svg>
`;
    el.appendChild(loaderOverlay);
    loaderOverlay.appendChild(loader);
  },
  updated(el, binding) {
    const loaderOverlay = document.getElementById(`loaderOverlay-${el.id}`);
    if (loaderOverlay && binding.value) {
      loaderOverlay.style.display = "block";
    }
    if (loaderOverlay && !binding.value) {
      loaderOverlay.style.display = "none";
    }
  },
};
