import { useLoginStore } from "@/stores/login";
import { isAuthorized } from "@/utils/isAuthorized";
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  useRoute,
} from "vue-router";
import { RouteNames } from "./RouteNames.enum";
import { ref } from "vue";
import { useHttp } from "@/composables/useHttp";
import { usePublicationSearchStore } from "@/stores/publication-search";

const routes: RouteRecordRaw[] = [
  {
    name: RouteNames.LOGIN,
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    name: RouteNames.LOGINREDIRECT,
    path: "/loginredirect",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "loginredirect" */ "../views/LoginRedirectView.vue"
      ),
  },
  {
    name: RouteNames.PROJECT_NEW,
    path: "/projects/new",
    component: () =>
      import(
        /* webpackChunkName: "ProjectNew" */ "../views/Projects/ProjectNew.vue"
      ),
  },
  {
    name: RouteNames.PROJECT_SETUP,
    path: "/projects/:projectId/setup",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "SetupView" */ "../views/Setup/SetupHomeView.vue"
      ),
    children: [
      {
        name: RouteNames.PROJECT_SETUP_LABELS,
        path: "labels",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SetupLabelsView" */ "../views/Setup/SetupLabelsView.vue"
          ),
        beforeEnter: (to, _, next) => {
          if (!to.params.projectType) {
            next({ name: RouteNames.HOME });
          } else {
            next();
          }
        },
      },
      {
        name: RouteNames.PROJECT_SETUP_TAGS,
        path: "tags",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "setupTagsView" */ "../views/Setup/SetupTagsView.vue"
          ),
        beforeEnter: (_, from, next) => {
          if (
            !(from.name === RouteNames.PROJECT_SETUP_LABELS) &&
            !(from.name === RouteNames.PROJECT_SETUP_USERS)
          ) {
            next({ name: RouteNames.HOME });
          } else {
            next();
          }
        },
      },
      {
        name: RouteNames.PROJECT_SETUP_USERS,
        path: "users",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SetupUsersView" */ "../views/Setup/SetupUsersView.vue"
          ),
      },
      {
        name: RouteNames.PROJECT_SETUP_COMPLETE,
        path: "complete",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SetupCompleteView" */ "../views/Setup/SetupCompleteView.vue"
          ),
        beforeEnter: (_, from, next) => {
          if (from.name !== RouteNames.PROJECT_SETUP_USERS) {
            next({ name: RouteNames.HOME });
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    name: RouteNames.SEARCH_HOME,
    path: "/projects/:projectId/search",
    props: true,
    redirect: { name: RouteNames.SEARCH_SEARCH },
    component: () =>
      import(
        /* webpackChunkName: "SearchView" */ "../views/Search/SearchView.vue"
      ),
    children: [
      {
        name: RouteNames.SEARCH_SEARCH,
        path: "search",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SearchPublicationsView" */ "../views/Search/SearchPublicationsView.vue"
          ),
      },
      {
        name: RouteNames.SEARCH_HISTORY,
        path: "searchhistory",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SearchHistoryView" */ "../views/Search/SearchHistoryView.vue"
          ),
      },
      {
        name: RouteNames.SEARCH_IMPORT,
        path: "importpublications",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "ImportPublicationsView" */ "../views/Search/ImportPublicationsView.vue"
          ),
        beforeEnter: (_to, _from, next) => {
          if (!isAuthorized(["editor", "admin", "owner"])) {
            next({ name: RouteNames.HOME });
          } else {
            next();
          }
        },
      },
      {
        name: RouteNames.SEARCH_SCHEDULED,
        path: "scheduled",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "ScheduledSearchesView" */ "../views/Search/ScheduledSearchesView.vue"
          ),
      },
    ],
  },
  {
    name: RouteNames.ABSTRACT_HOME,
    path: "/projects/:projectId/abstract",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "AbstractHomeView" */ "../views/Abstract/AbstractHomeView.vue"
      ),
    children: [
      {
        name: RouteNames.ABSTRACT_LIST,
        path: "",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "AbstractListView" */ "../views/Abstract/AbstractListView.vue"
          ),
      },
      {
        name: RouteNames.ABSTRACT_SCREEN,
        path: ":publicationId",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "ScreenAbstractView" */ "../views/Abstract/AbstractScreenView.vue"
          ),
      },
    ],
  },
  {
    name: RouteNames.FULLTEXT_HOME,
    path: "/projects/:projectId/fulltext",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "FullTextHomeView" */ "../views/FullText/FullTextHomeView.vue"
      ),
    children: [
      {
        name: RouteNames.FULLTEXT_LIST,
        path: "",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "FullTextListView" */ "../views/FullText/FullTextListView.vue"
          ),
      },
      {
        name: RouteNames.FULLTEXT_SCREEN,
        path: ":publicationId",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "FullTextScreenView" */ "../views/FullText/FullTextScreenView.vue"
          ),
      },
    ],
  },
  {
    name: RouteNames.SYNTHESIS_HOME,
    path: "/projects/:projectId/synthesis",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "SynthesisHomeView" */ "../views/Synthesis/SynthesisHomeView.vue"
      ),
    children: [
      {
        name: RouteNames.SYNTHESIS_OVERVIEW,
        path: "",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SynthesisOverviewView" */ "../views/Synthesis/SynthesisOverviewView.vue"
          ),
      },
      {
        name: RouteNames.SYNTHESIS_PRISMA,
        path: "prisma",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SynthesisOverviewView" */ "../views/Synthesis/SynthesisPrismaView.vue"
          ),
      },
      {
        name: RouteNames.SYNTHESIS_APPRAISAL,
        path: "appraisal",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SynthesisOverviewView" */ "../views/Synthesis/SynthesisAppraisalTableView.vue"
          ),
      },
      {
        name: RouteNames.SYNTHESIS_EXTRACTION,
        path: "extraction",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SynthesisOverviewView" */ "../views/Synthesis/SynthesisDataExtractionTableView.vue"
          ),
      },
    ],
  },
  {
    name: RouteNames.PROJECT,
    path: "/projects/:projectId",
    // TODO: should be current phase later
    redirect: "/projects/:projectId/abstract",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "ProjectView" */ "../views/Projects/ProjectView.vue"
      ),
  },
  {
    name: RouteNames.PROJECTS,
    path: "/projects",
    component: () =>
      import(
        /* webpackChunkName: "ProjectsView" */ "../views/Projects/ProjectsView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    name: RouteNames.HOME,
    path: "/",
    redirect: "/projects",
    component: () => "<div></div>",
    meta: { requiresAuth: true },
  },
  {
    name: RouteNames.NOT_FOUND,
    path: "/:pathMatch(.*)*",
    component: () =>
      import(
        /* webpackChunkName: "NotFoundView" */ "../views/Error/NotFoundView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// If the current bearer token has expired, redirect to the login screen.
router.beforeEach((to, from, next) => {
  if (!to.name) {
    return true;
  }

  const loginStore = useLoginStore();
  const token = loginStore.getAuthDetails;

  if (token) {
    const expires = new Date(token.tokenExpirationDate);
    const now = new Date();

    // Clear the current access token if it has expired, so the logic for returning to the login page will trigger subsequently.
    if (expires < now) {
      loginStore.setAccessToken(null);
    }
  }

  const loginRoute = RouteNames.LOGIN.valueOf();

  const loginRedirectRoute = RouteNames.LOGINREDIRECT.valueOf();

  const currentRoute = to.name.toString();

  const isLoginRoute = currentRoute === loginRoute;
  const isLoginRedirectRoute = currentRoute === loginRedirectRoute;
  const noToken = token === null;

  if (isLoginRedirectRoute) {
    next();
  } else if (noToken && !isLoginRoute) {
    next({ name: RouteNames.LOGIN });
  } else {
    next();
  }
});

export default router;
