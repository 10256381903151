import { OrderArticleRequestInput, Publication } from "@/types/publications";
import { defineStore } from "pinia";

export type OrderArticleDialogState = {
  title: string;
  publications: Publication[];
  callback: ((request: OrderArticleRequestInput) => Promise<void>) | null;
  isVisible: boolean;
};

export type OrderArticleDialogContent = {
  title: string;
  publications: Publication[];
  callback: ((request: OrderArticleRequestInput) => Promise<void>) | null;
};

export const useOrderArticleDialogStore = defineStore("dialog/orderArticle", {
  state: (): OrderArticleDialogState => ({
    title: "",
    publications: [],
    callback: null,
    isVisible: false,
  }),
  actions: {
    showOrderArticleDialog(model: OrderArticleDialogContent): void {
      this.title = model.title;
      this.callback = model.callback;
      this.isVisible = true;
      this.publications = model.publications;
    },
    hideOrderArticleDialog(): void {
      this.$reset();
    },
  },
});
