import { defineStore } from "pinia";
import { ProjectPermissions } from "@/types/authorization";

export type AuthorizationsState = {
  projectPermissions: ProjectPermissions;
};

export const useAuthorizationsStore = defineStore("authorizations", {
  state: (): AuthorizationsState => ({
    projectPermissions: {
      viewerForProjects: [],
      editorForProjects: [],
      adminForProjects: [],
      ownerForProjects: [],
    },
  }),
  actions: {
    updateProjectPermissionLists(projectPermissions: ProjectPermissions) {
      this.projectPermissions = projectPermissions;
    },
  },
});
