
import { defineComponent } from "vue";

export default defineComponent({
  name: "InputComponent",
  props: ["modelValue", "identifier"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
