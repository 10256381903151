import { useAuthorizationsStore } from "@/stores/authorizations";
import { useProjectsStore } from "@/stores/projects";
import { UserType } from "@/types/users";

export const isAuthorized = (
  allowedRoles: UserType[],
  id?: number
): boolean => {
  const authorizationsStore = useAuthorizationsStore();
  const projectsStore = useProjectsStore();

  return allowedRoles?.some((role) => {
    if (role === "editor") {
      return id
        ? authorizationsStore.projectPermissions.editorForProjects?.includes(id)
        : projectsStore.activeProject &&
            authorizationsStore.projectPermissions.editorForProjects?.includes(
              projectsStore.activeProject.id
            );
    }
    if (role === "viewer") {
      return id
        ? authorizationsStore.projectPermissions.viewerForProjects?.includes(id)
        : projectsStore.activeProject &&
            authorizationsStore.projectPermissions.viewerForProjects?.includes(
              projectsStore.activeProject.id
            );
    }
    if (role === "owner") {
      return id
        ? authorizationsStore.projectPermissions.ownerForProjects?.includes(id)
        : projectsStore.activeProject &&
            authorizationsStore.projectPermissions.ownerForProjects?.includes(
              projectsStore.activeProject.id
            );
    }
    if (role === "admin") {
      return id
        ? authorizationsStore.projectPermissions.adminForProjects?.includes(id)
        : projectsStore.activeProject &&
            authorizationsStore.projectPermissions.adminForProjects?.includes(
              projectsStore.activeProject.id
            );
    }
  });
};
