import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "@/http";
import router from "@/router";
import { Ref, ref } from "vue";
import { useErrorDialog } from "./useErrorDialog";
import { RouteNames } from "@/router/RouteNames.enum";

type HttpInput = AxiosRequestConfig & {
  errorDialog?: boolean;
};

export type HttpReturn = {
  response: Ref<AxiosResponse | undefined>;
  error: Ref<AxiosError>;
};

export const useHttp = async ({
  url,
  method,
  data,
  headers,
  errorDialog = true,
}: HttpInput): Promise<HttpReturn> => {
  const response = ref<AxiosResponse>();
  const error = ref();

  try {
    response.value = await apiClient.request({
      method,
      url,
      data,
      headers,
    });
  } catch (err) {
    error.value = err;
    if (axios.isAxiosError(err) && err?.response?.status === 401) {
      router.push({ name: RouteNames.LOGIN });
    } else if (errorDialog) useErrorDialog(error.value);
  }

  return { response, error };
};
