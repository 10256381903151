
import { computed } from "@vue/reactivity";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { useConfirmationDialogStore } from "./stores/confirmation-dialog";
import { useErrorDialogStore } from "./stores/error-dialog";
import { useLoginStore } from "./stores/login";
import { useOrderArticleDialogStore } from "./stores/order-article-dialog";
import { useProjectsStore } from "./stores/projects";
import { usePublicationSearchStore } from "./stores/publication-search";

export default defineComponent({
  name: "AppComponent",
  setup() {
    const route = useRoute();

    const projectsStore = useProjectsStore();
    const publicationSearchStore = usePublicationSearchStore();
    const loginStore = useLoginStore();
    const errorDialog = useErrorDialogStore();
    const confirmationDialog = useConfirmationDialogStore();
    const orderArticleDialog = useOrderArticleDialogStore();

    const alerts = computed(() => publicationSearchStore.alerts);
    const token = loginStore.getAuthDetails;
    const loggedInUser = loginStore.getAuthDetails?.username;

    if (loggedInUser) {
      projectsStore.fetchProjects();
    }

    if (!alerts.value && token) {
      publicationSearchStore.fetchAlertsForUser();
    }

    const modalOpen = computed(
      () =>
        errorDialog.isVisible ||
        confirmationDialog.isVisible ||
        orderArticleDialog.isVisible
    );

    return {
      route,
      orderArticleDialog,
      modalOpen,
    };
  },
});
