import {
  AppraisalCriterium,
  DataExtractionCategory,
  Label,
  Project,
  ProjectProgress,
  ProjectSorting,
  UserArchivedProject,
} from "@/types/projects";
import { defineStore } from "pinia";
import { HttpReturn, useHttp } from "@/composables/useHttp";
import { usePublicationsStore } from "./publications";
import { usePublicationSearchStore } from "./publication-search";
import { useReportsStore } from "./reports";
import { useAuthorizationsStore } from "./authorizations";

export interface ProjectState {
  projects: Project[];
  activeProject: Project | null;
  activeProjectProgress: ProjectProgress | null;
  archivedProjects: UserArchivedProject[];
  selectedLabels: Label[];
  appraisalCriterium: AppraisalCriterium[] | null;
  dataExtractionCategories: DataExtractionCategory[] | null;
}

export const useProjectsStore = defineStore("projects", {
  state: (): ProjectState => ({
    projects: [],
    activeProject: null,
    activeProjectProgress: null,
    archivedProjects: [],
    appraisalCriterium: null,
    selectedLabels: [],
    dataExtractionCategories: [],
  }),
  actions: {
    syncActiveProjectToAllProjects(activeProject: Project): void {
      const changedProjectIndex = this.projects.findIndex(
        (project) => project.id === activeProject.id
      );
      if (changedProjectIndex > -1) {
        this.projects[changedProjectIndex] = activeProject;
      }
    },
    projectSwitchResets() {
      const publicationsStore = usePublicationsStore();
      const publicationSearchStore = usePublicationSearchStore();
      const reportsStore = useReportsStore();

      //reset the stores upon switching project. To prevent briefly displaying data of the previous project.
      publicationsStore.$reset();

      //Keep only the alerts on reset. To prevent Alert notification from disappearing. This part of the stored search data does not cause graphic issues.
      const alerts = publicationSearchStore.alerts;
      publicationSearchStore.$reset();
      publicationSearchStore.alerts = alerts;

      reportsStore.$reset();
    },
    async fetchProjects(): Promise<void> {
      const authorizationsStore = useAuthorizationsStore();
      const { response } = await useHttp({
        method: "GET",
        url: `/Project/UserProjects`,
      });
      if (response?.value?.data) {
        // also set permissions of all projects for this user in the authorizationsStore
        authorizationsStore.updateProjectPermissionLists(
          response.value.data.permissions
        );
        this.projects = response.value.data.projects;
      }
    },
    async fetchProject(projectId: number): Promise<void> {
      const { response } = await useHttp({
        method: "GET",
        url: `/Project?id=${projectId}`,
      });
      if (response?.value?.data) {
        // only update selectedLabels when project changes to keep filter selection
        if (this.activeProject?.id !== response.value.data.id) {
          this.selectedLabels = response.value.data.labels;
        }
        this.activeProject = response.value.data;
      }
    },
    async fetchArchivedProjects(): Promise<void> {
      const { response } = await useHttp({
        method: "GET",
        url: `/Project/GetArchivedProjects`,
      });
      if (response?.value?.data) {
        this.archivedProjects = response.value.data;
      }
    },

    async addProjectToArchive(projectId: number): Promise<Partial<HttpReturn>> {
      const { error, response } = await useHttp({
        method: "POST",
        url: `Project/AddProjectToArchive?projectId=${projectId}`,
        data: {
          projectId,
        },
      });
      if (response.value?.data) {
        this.fetchProjects();
        this.fetchArchivedProjects();
      }
      return { error };
    },
    async removeProjectFromArchive(
      projectId: number
    ): Promise<Partial<HttpReturn>> {
      const { error, response } = await useHttp({
        method: "DELETE",
        url: `Project/RemoveProjectFromArchive?projectId=${projectId}`,
        data: {
          projectId,
        },
      });
      if (response.value?.data) {
        this.fetchProjects();
        this.fetchArchivedProjects();
      }
      return { error };
    },
    setActiveProject(projectId: number) {
      const selectedProject = this.projects.find(
        (project) => project.id === projectId
      );
      if (selectedProject) {
        this.activeProject = selectedProject;
        this.projectSwitchResets();
      }
    },
    resetActiveProject() {
      this.activeProject = null;
      this.projectSwitchResets();
    },
    async fetchAppraisalCriteria(projectId: number) {
      const { response } = await useHttp({
        method: "GET",
        url: `/Project/GetAppraisalCriteria?projectId=${projectId}`,
      });
      if (response.value?.data) {
        this.appraisalCriterium = response.value.data;
      }
    },
    async fetchProjectProgress(projectId: number): Promise<void> {
      const { response } = await useHttp({
        method: "GET",
        url: `/Report/ProjectProgress?projectId=${projectId}`,
      });
      if (response.value?.data) {
        this.activeProjectProgress = response.value.data;
      }
    },
    async fetchExtractionData(projectId: number): Promise<void> {
      const { response } = await useHttp({
        method: "GET",
        url: `/Project/GetDataExtractionCategories?projectId=${projectId}`,
      });
      if (response.value?.data) {
        this.dataExtractionCategories = response.value.data;
      }
    },
    async replaceLabels({
      projectId,
      labels,
    }: {
      projectId: number;
      labels: { color: string; text: string }[];
    }) {
      const { error, response } = await useHttp({
        method: "POST",
        url: "/Project/AddOrReplaceCustomAnnotationLabels",
        data: {
          projectId,
          labels,
        },
      });
      if (response.value?.data && this.activeProject?.id === projectId) {
        this.activeProject = {
          ...this.activeProject,
          labels: response.value.data,
        };
        this.syncActiveProjectToAllProjects(this.activeProject);
      }
      if (error.value) {
        return { error: error.value };
      }
      return { error: undefined };
    },
    async uploadDictionaryToLabel({
      labelId,
      formData,
    }: {
      labelId: number;
      formData: FormData;
    }): Promise<Partial<HttpReturn>> {
      const { error, response } = await useHttp({
        method: "POST",
        url: "Project/AddLabelDictionary",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.value?.data && this.activeProject?.labels) {
        const changedLabelIndex = this.activeProject.labels?.findIndex(
          (label) => label.id === labelId
        );

        if (changedLabelIndex > -1) {
          this.activeProject.labels[changedLabelIndex] = response.value.data;
          this.syncActiveProjectToAllProjects(this.activeProject);
        }
      }
      return { error };
    },
    async createNewEntity({
      name,
      color,
    }: {
      name: string;
      color: string;
    }): Promise<Partial<HttpReturn>> {
      if (this.activeProject) {
        const currentLabels = this.activeProject.labels
          ? this.activeProject.labels.map((label) => ({
              text: label.text,
              color: label.color,
              id: label.id,
            }))
          : [];
        const newLabels = [...currentLabels, { text: name, color, id: 0 }];
        const { error, response } = await useHttp({
          method: "POST",
          url: "/Project/AddOrReplaceCustomAnnotationLabels",
          data: {
            projectId: this.activeProject.id,
            labels: newLabels,
          },
        });
        const projectId = this.activeProject.id;
        if (projectId && response.value?.data) {
          const newLabel = this.activeProject.labels?.find(
            (label) => (label.text = name)
          );
          if (newLabel) {
            this.selectedLabels = [...this.selectedLabels, newLabel];
          }

          this.activeProject = {
            ...this.activeProject,
            labels: response.value.data,
          };
          this.syncActiveProjectToAllProjects(this.activeProject);
          return { error };
        }
      }
      return { error: undefined };
    },
    async addNewTag(tag: string): Promise<Partial<HttpReturn>> {
      if (this.activeProject) {
        const projectId = this.activeProject.id;
        const oldTags = this.activeProject.publicationTags ?? [];
        const newTags = oldTags.includes(tag) ? oldTags : [...oldTags, tag];
        const { error } = await useHttp({
          method: "PUT",
          url: `/Project/AddOrReplacePublicationTags?projectId=${projectId}`,
          data: newTags,
        });
        this.activeProject = {
          ...this.activeProject,
          publicationTags: newTags,
        };
        this.syncActiveProjectToAllProjects(this.activeProject);
        return { error };
      }
      return { error: undefined };
    },
    async replaceTags({
      projectId,
      newTags,
    }: {
      projectId: number;
      newTags: string[];
    }): Promise<Partial<HttpReturn>> {
      const { error } = await useHttp({
        method: "PUT",
        url: `/Project/AddOrReplacePublicationTags?projectId=${projectId}`,
        data: newTags,
      });
      if (!error.value?.message && this.activeProject) {
        this.activeProject = {
          ...this.activeProject,
          publicationTags: newTags,
        };
        this.syncActiveProjectToAllProjects(this.activeProject);
      }
      return { error };
    },
    async replaceExclusionCriteria({
      projectId,
      newExclusionCriteria,
    }: {
      projectId: number;
      newExclusionCriteria: string[];
    }): Promise<Partial<HttpReturn>> {
      const { error } = await useHttp({
        method: "PUT",
        url: `/Project/AddOrReplaceExclusionCriteria?projectId=${projectId}`,
        data: newExclusionCriteria,
      });
      if (!error.value?.message && this.activeProject) {
        this.activeProject = {
          ...this.activeProject,
          exclusionCriteria: newExclusionCriteria,
        };
        this.syncActiveProjectToAllProjects(this.activeProject);
      }
      return { error };
    },
    async addExtractionCategory({
      projectId,
      categoryName,
      categoryId,
    }: {
      projectId: number;
      categoryName: string;
      categoryId: number;
    }): Promise<Partial<HttpReturn>> {
      const { error, response } = await useHttp({
        method: "POST",
        url: `/Project/AddOrReplaceDataExtractionCategory?projectId=${projectId}&categoryName=${categoryName}&categoryId=${categoryId}`,
      });

      return { error, response };
    },
    async addExtractionCategoryField({
      projectId,
      categoryId,
      fieldName,
      fieldId,
    }: {
      projectId: number;
      categoryId: number;
      fieldName: string;
      fieldId: number;
    }): Promise<Partial<HttpReturn>> {
      const { error, response } = await useHttp({
        method: "POST",
        url: `/Project/AddOrReplaceDataExtractionField?projectId=${projectId}&categoryId=${categoryId}&fieldName=${fieldName}&fieldId=${fieldId}`,
      });
      return { error, response };
    },
    async addCollaborators({
      projectId,
      viewerIds,
      editorIds,
    }: {
      projectId: number;
      viewerIds: number[];
      editorIds: number[];
    }): Promise<Partial<HttpReturn>> {
      const { error, response } = await useHttp({
        method: "POST",
        url: "Project/AddCollaboratorsToProject",
        data: {
          projectId,
          viewerIds: [...viewerIds],
          editorIds: [...editorIds],
        },
      });
      if (response.value?.data) {
        this.activeProject = response.value.data;
        this.syncActiveProjectToAllProjects(response.value.data);
      }
      return { error };
    },
    async removeCollaborators({
      projectId,
      viewerIds,
      editorIds,
    }: {
      projectId: number;
      viewerIds: number[];
      editorIds: number[];
    }): Promise<Partial<HttpReturn>> {
      const { error, response } = await useHttp({
        method: "POST",
        url: "Project/RemoveCollaboratorsFromProject",
        data: {
          projectId,
          viewerIds,
          editorIds,
        },
      });
      if (response.value?.data) {
        this.activeProject = response.value.data;
        this.syncActiveProjectToAllProjects(response.value.data);
      }
      return { error };
    },
    getSortedProjects(sorting: ProjectSorting) {
      const authorizationsStore = useAuthorizationsStore();
      // compares projects on the sortBy property set in the input
      const sortedOnDate = this.projects.sort((a, b) => {
        const valueA = a.createdTimestamp;
        const valueB = b.createdTimestamp;

        if (sorting.direction === "DESC") {
          if (valueA > valueB) return -1;
          if (valueA < valueB) return 1;
          return 0;
        }
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      });

      if (sorting.splitItems) {
        if (sorting.sortBy === "role") {
          const editorProjects = sortedOnDate.filter((project) =>
            authorizationsStore.projectPermissions.editorForProjects?.some(
              (pid) => pid === project.id
            )
          );
          const viewerProjects = sortedOnDate.filter((project) =>
            authorizationsStore.projectPermissions.viewerForProjects?.some(
              (pid) => pid === project.id
            )
          );
          const adminProjects = sortedOnDate.filter((project) =>
            authorizationsStore.projectPermissions.adminForProjects?.some(
              (pid) => pid === project.id
            )
          );
          const ownerProjects = sortedOnDate.filter((project) =>
            authorizationsStore.projectPermissions.ownerForProjects?.some(
              (pid) => pid === project.id
            )
          );
          return sorting.direction === "ASC"
            ? [adminProjects, editorProjects, ownerProjects, viewerProjects]
            : [viewerProjects, ownerProjects, editorProjects, adminProjects];
        }
      }

      return [sortedOnDate];
    },
  },
});
