
import { defineComponent } from "vue";
import RSLogo from "@/assets/logos/Research Solutions Logo.svg";

export default defineComponent({
  name: "BottomBarComponent",

  setup() {
    return {
      RSLogo,
    };
  },
});
