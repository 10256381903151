
import { PublicationFilter } from "@/types/generic";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "FilterOnPublicationTypeComponent",
  emits: ["onSetFilterSelection"],
  setup(_, { emit }) {
    const { t } = useI18n();
    const filters = ref<PublicationFilter[]>([
      {
        text: t("components.FilterOnPublicationType.priceKnown"),
        value: "paid",
        selected: false,
      },
      {
        text: t("components.FilterOnPublicationType.inLibrary"),
        value: "alreadyPurchased",
        selected: false,
      },
      {
        text: t("components.FilterOnPublicationType.openAccess"),
        value: "openAccess",
        selected: false,
      },
      {
        text: t("components.FilterOnPublicationType.subscription"),
        value: "magazineSubscription",
        selected: false,
      },
      {
        text: t("components.FilterOnPublicationType.priceUnknown"),
        value: "unknown",
        selected: false,
      },
    ]);

    const onToggleFilter = (filterText: string) => {
      filters.value = filters.value.map((filter) =>
        filter.text === filterText
          ? {
              text: filter.text,
              value: filter.value,
              selected: !filter.selected,
            }
          : filter
      );
      emit("onSetFilterSelection", filters.value);
    };

    return {
      filters,
      onToggleFilter,
    };
  },
});
