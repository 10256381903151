
import { defineComponent, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import Button from "@/components/UI/ButtonComponent.vue";
import Input from "@/components/UI/InputComponent.vue";
import FilterOnPublicationType from "@/components/generic/FilterOnPublicationTypeComponent.vue";
import { useOrderArticleDialogStore } from "@/stores/order-article-dialog";
import { PublicationPriceInfoObject } from "@/types/publications";
import { useHttp } from "@/composables/useHttp";
import ArticleGalaxyLogo from "@/assets/logos/Article Galaxy Logo.svg";
import { useProjectsStore } from "@/stores/projects";
import { PublicationFilter } from "@/types/generic";

export default defineComponent({
  name: "OrderArticleDialogComponent",
  components: { Button, Input, FilterOnPublicationType },

  setup() {
    const { t } = useI18n();
    const orderArticleDialogStore = useOrderArticleDialogStore();
    const getCopyRightPriceTitle = ref<{ key: number; text: string }[]>([]);
    const priceInfo = ref<PublicationPriceInfoObject>({});
    const selectedFilters = ref<string[]>([]);

    const projectsStore = useProjectsStore();

    const getCopyRightPrice = (id: number) => {
      const info = priceInfo.value[id];

      if (!info) return t("dialogs.orderArticle.loading");

      if (info.getPriceDescription?.startsWith("$")) {
        return info.getPriceDescription;
      }

      if (
        info.getPriceDescription === "magazineSubscription" &&
        !getCopyRightPriceTitle.value.find((c) => c.key === id)
      ) {
        getCopyRightPriceTitle.value.push({
          key: id,
          text: t("dialogs.orderArticle.checkMailForDownload"),
        });
      }

      return t(`dialogs.orderArticle.${info.getPriceDescription}`);
    };

    const onCancel = () => {
      orderArticleDialogStore.hideOrderArticleDialog();
    };

    const getPriceInfo = async ({
      publicationIds,
    }: {
      publicationIds: number[];
    }): Promise<void> => {
      const { response } = await useHttp({
        method: "POST",
        url: "/Publication/GetPriceIndication",
        data: publicationIds,
      });
      if (response?.value?.data) {
        priceInfo.value = response.value.data;
      }
    };

    const { isVisible, publications, title, callback } = storeToRefs(
      orderArticleDialogStore
    );

    getPriceInfo({
      publicationIds: publications.value.map((p) => p.id),
    });

    const publicationsExpanded = computed(() => {
      return publications?.value
        ?.filter((p) => {
          if (
            // no filters selected
            selectedFilters.value?.length === 0
          )
            return true;
          const price = priceInfo.value[p.id]?.getPriceDescription;
          // no price means still loading
          if (!price) return true;
          // if filter is selected results are filtered away
          if (selectedFilters.value?.includes(price)) {
            return false;
          }
          // filter for paid articles because these prices can vary
          if (selectedFilters.value?.includes("paid") && price.includes("$")) {
            return false;
          }
          // if not in filtered results show this article
          return true;
        })
        .map((p) => ({
          id: p.id,
          title: p.title,
          authors: p.authors,
          date: p.date,
          customerReference: projectsStore.activeProject?.name ?? "",
          selected: true,
        }));
    });

    const onProceed = async () => {
      if (
        callback.value &&
        priceInfo.value &&
        publicationsExpanded.value &&
        publicationsExpanded.value.length > 0
      ) {
        const publicationsToOrder = publicationsExpanded.value.map((p) => ({
          ...p,
          magazineSubscription: priceInfo.value[p.id].magazineSubscription,
        }));

        await callback.value(publicationsToOrder.filter((p) => p.selected));
      }
      orderArticleDialogStore.hideOrderArticleDialog();
    };

    const onSetFilterSelection = (filters: PublicationFilter[]) => {
      selectedFilters.value = filters
        .filter((filter) => filter.selected)
        .map((filter) => filter.value);
    };

    return {
      onCancel,
      onProceed,
      publicationsExpanded,
      isVisible,
      title,
      getCopyRightPrice,
      ArticleGalaxyLogo,
      getCopyRightPriceTitle,
      onSetFilterSelection,
    };
  },
});
