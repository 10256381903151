import { defineStore } from "pinia";
import { useHttp } from "@/composables/useHttp";
import {
  DailySearch,
  DailySearchFromSearchRequest,
  PublicationFetchResult,
} from "@/types/publicationSearch";
import { useProjectsStore } from "./projects";

export interface ReportsState {
  alerts: DailySearch[] | null;
  scheduledSearches: DailySearch[];
  searches: PublicationFetchResult[];
  selectedSearch: PublicationFetchResult | null;
  selectedScheduledSearch: DailySearch | null;
}

export const usePublicationSearchStore = defineStore("publicationSearch", {
  state: (): ReportsState => ({
    alerts: null,
    scheduledSearches: [],
    searches: [],
    selectedSearch: null,
    selectedScheduledSearch: null,
  }),
  actions: {
    selectSearchById(id: number) {
      const selectedSearch = this.searches.find((search) => search.id === id);
      if (selectedSearch) {
        this.selectedSearch = selectedSearch;
      }
    },
    selectScheduledSearchById(id: number) {
      const selectedScheduledSearch = this.scheduledSearches.find(
        (search) => search.id === id
      );
      if (selectedScheduledSearch) {
        this.selectedScheduledSearch = selectedScheduledSearch;
      }
    },
    async fetchAlertsForUser() {
      const { response } = await useHttp({
        method: "GET",
        url: "/DailySearch/GetUnhandledDailySearchesForUser",
      });

      const projectStore = useProjectsStore();

      if (response?.value?.data) {
        // adds the project to this alert from the project store
        this.alerts = response.value.data.map((alert: DailySearch) => ({
          ...alert,
          project: projectStore.projects.find(
            (project) => project.id === alert.projectId
          ),
        }));
      }
    },
    removeAlertById(id: number) {
      if (this.alerts) {
        this.alerts = this.alerts.filter((alert) => alert.id !== id);
      }
    },
    removeAllAlerts() {
      this.alerts = [];
    },
    async fetchSearchHistory(projectId: number) {
      const { response } = await useHttp({
        method: "GET",
        url: `/Publication/GetPublicationFetchResults?projectId=${projectId}`,
      });

      if (response?.value?.data) {
        this.searches = response.value.data;
      }
    },
    async createRepeatableSearchFromFetchResultId(searchId: number) {
      const { response } = await useHttp({
        method: "POST",
        url: `/DailySearch/SetDailySearchFromHistory?publicationFetchResultId=${searchId}`,
      });

      if (response?.value?.data) {
        this.scheduledSearches = [
          ...this.scheduledSearches,
          response.value.data,
        ];
      }
    },
    async createRepeatableSearchFromSearchAction(
      data: DailySearchFromSearchRequest
    ) {
      const { response } = await useHttp({
        method: "POST",
        url: "/DailySearch/SetDailySearchFromSearch",
        data,
      });

      if (response?.value?.data) {
        this.scheduledSearches = [
          ...this.scheduledSearches,
          response.value.data,
        ];
      }
    },
    async fetchScheduledSearches(projectId: number) {
      const { response } = await useHttp({
        method: "GET",
        url: `/DailySearch/GetProjectDailySearches?projectId=${projectId}`,
      });

      if (response?.value?.data) {
        this.scheduledSearches = response.value.data;
      }
    },
    async toggleDailySearchActive({
      searchId,
      active,
    }: {
      searchId: number;
      active: boolean;
    }) {
      const { response } = await useHttp({
        method: "PUT",
        url: `/DailySearch/ToggleDailySearchActive?dailySearchId=${searchId}&active=${active}`,
      });

      if (response?.value?.data) {
        this.scheduledSearches = this.scheduledSearches.map((search) =>
          search.id === searchId ? response?.value?.data : search
        );
        if (this.selectedScheduledSearch?.id === searchId) {
          this.selectedScheduledSearch = response.value.data;
        }
      }
    },
  },
});
