export enum RouteNames {
  LOGINREDIRECT = "LOGINREDIRECT",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  NOT_FOUND = "NOT_FOUND",
  ERROR = "ERROR",
  HOME = "HOME",
  PROJECTS = "PROJECTS",
  PROJECTS_ARCHIVE = "PROJECT_ARCHIVE",
  PROJECT = "PROJECT",
  PROJECT_NEW = "PROJECT_NEW",
  PROJECT_SETUP = "PROJECT_SETUP",
  PROJECT_SETUP_TAGS = "PROJECT_SETUP_TAGS",
  PROJECT_SETUP_LABELS = "PROJECT_SETUP_LABELS",
  PROJECT_SETUP_DICTIONARIES = "PROJECT_SETUP_DICTIONARIES",
  PROJECT_SETUP_USERS = "PROJECT_SETUP_USERS",
  PROJECT_SETUP_COMPLETE = "PROJECT_SETUP_COMPLETE",
  SEARCH_HOME = "SEARCH_HOME",
  SEARCH_SEARCH = "SEARCH_SEARCH",
  SEARCH_IMPORT = "SEARCH_IMPORT",
  SEARCH_HISTORY = "SEARCH_HISTORY",
  SEARCH_SCHEDULED = "SEARCH_SCHEDULED",
  SEARCH_COMPLETE = "SEARCH_COMPLETE",
  ABSTRACT_HOME = "ABSTRACT_HOME",
  ABSTRACT_LIST = "ABSTRACT_LIST",
  ABSTRACT_SCREEN = "ABSTRACT_SCREEN",
  ABSTRACT_COMPLETE = "ABSTRACT_COMPLETE",
  FULLTEXT_HOME = "FULLTEXT_HOME",
  FULLTEXT_LIST = "FULLTEXT_LIST",
  FULLTEXT_SCREEN = "FULLTEXT_SCREEN",
  FULLTEXT_COMPLETE = "FULLTEXT_COMPLETE",
  SYNTHESIS_HOME = "SYNTHESIS_HOME",
  SYNTHESIS_EXPORT = "SYNTHESIS_EXPORT",
  SYNTHESIS_PRISMA = "SYNTHESIS_PRISMA",
  SYNTHESIS_APPRAISAL = "SYNTHESIS_APPRAISAL",
  SYNTHESIS_COMPLETE = "SYNTHESIS_COMPLETE",
  SYNTHESIS_OVERVIEW = "SYNTHESIS_OVERVIEW",
  SYNTHESIS_EXTRACTION = "SYNTHESIS_EXTRACTION",
}
