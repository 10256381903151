import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    id: _ctx.identifier,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    class: "rounded border border-solid border-color-outline disabled:text-opacity-40 disabled:bg-color-background-light"
  }, null, 8, _hoisted_1)), [
    [_vModelText, _ctx.value]
  ])
}