import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed w-full max-h-[95vh] bg-color-white overflow-y-auto max-w-2xl px-4 shadow-xl z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
}
const _hoisted_2 = { class: "inline-block w-full justify-between items-start rounded-t border-b p-5" }
const _hoisted_3 = { class: "inline-block w-full p-6" }
const _hoisted_4 = { class: "text-base leading-relaxed whitespace-pre-line" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "flex justify-end flex-nowrap w-full border-t p-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.$t(_ctx.title)), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", { innerHTML: _ctx.message }, null, 8, _hoisted_5)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.showCancelButton)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: "mr-4",
                onClick: _ctx.onCancel,
                buttonStyle: 'secondary'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("dialogs.confirmation.cancel")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Button, {
            onClick: _ctx.onProceed,
            buttonStyle: 'primary'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.confirmationButtonText ?? _ctx.$t("dialogs.confirmation.confirm")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]))
    : _createCommentVNode("", true)
}