import { PublicationReportData } from "@/types/reports";
import { defineStore } from "pinia";
import { useHttp } from "@/composables/useHttp";
import { Project } from "@/types/projects";

export interface ReportsState {
  publicationReportData: PublicationReportData | null;
  project: Project | null;
}

export const useReportsStore = defineStore("reports", {
  state: (): ReportsState => ({
    publicationReportData: null,
    project: null,
  }),
  actions: {
    async fetchSynthesisReport(projectId: number): Promise<void> {
      const { response } = await useHttp({
        method: "GET",
        url: `/Report/SynthesisOverview?projectId=${projectId}`,
      });
      if (response?.value?.data) {
        this.publicationReportData = response.value.data;
      }
    },
    async fetchProjectWithAllAppraisalData(projectId: number): Promise<void> {
      const { response } = await useHttp({
        method: "GET",
        url: `/Project/ProjectWithAllAppraisalData?id=${projectId}`,
      });
      if (response?.value?.data) {
        this.project = response.value.data;
      }
    },
  },
});
