import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import axios from "./http";
import { AxiosKey } from "./symbols";

import BottomBar from "./components/generic/BottomBarComponent.vue";
import ErrorDialog from "./components/dialogs/ErrorDialogComponent.vue";
import ConfirmationDialog from "./components/dialogs/ConfirmationDialogComponent.vue";
import OrderArticleDialog from "./components/dialogs/OrderArticleDialogComponent.vue";

import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "@/theme/tables/defaultTableStyling.scss";

import { LoadingDirective } from "./directives/LoadingDirective";
import { ClickOutsideDirective } from "./directives/ClickOutsideDirective";

import { i18n } from "./translations/translations";

// PrimeVue
import PrimeVue from "primevue/config";
import Dropdown from "primevue/dropdown";
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css";

import "./index.css";

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(i18n);
app.use(router);
app.use(PrimeVue);

app.provide(AxiosKey, axios);

app.directive("loading", LoadingDirective);
app.directive("clickOutside", ClickOutsideDirective);

app.component("BottomBar", BottomBar);
app.component("ErrorDialog", ErrorDialog);
app.component("ConfirmationDialog", ConfirmationDialog);
app.component("OrderArticleDialog", OrderArticleDialog);
app.component("AgGrid", AgGridVue);
app.component("DropDown", Dropdown);

router.isReady().then(() => {
  app.mount("#app");
});
