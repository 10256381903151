
import { defineComponent, PropType } from "vue";

type ButtonStyle = "primary" | "secondary" | "tertiary";

export default defineComponent({
  name: "ButtonComponent",
  props: {
    buttonStyle: { type: String as PropType<ButtonStyle>, required: true },
  },
});
