import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex text-color-primary text-sm" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter) => {
      return (_openBlock(), _createElementBlock("a", {
        key: filter.text,
        class: _normalizeClass(["px-4 py-2 flex items-center border border-color-background-dark first-of-type:rounded-l last-of-type:rounded-r cursor-pointer", 
        !filter.selected
          ? 'bg-color-background-light text-color-primary'
          : 'bg-color-background-dark text-color-white'
      ]),
        onClick: ($event: any) => (_ctx.onToggleFilter(filter.text))
      }, _toDisplayString(filter.text), 11, _hoisted_2))
    }), 128))
  ]))
}