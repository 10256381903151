import { defineStore } from "pinia";

export type LoginResponse = {
  id: number;
  role: string;
  email: string;
  loginResultText: string;
  token: string;
  tokenExpirationDate: Date;
  fullName: string;
  username: string;
  canOrder: boolean;
};

export type LoginState = {
  loginResponse: LoginResponse | null;
};

export const useLoginStore = defineStore("login", {
  state: (): LoginState => ({
    loginResponse: null,
  }),
  getters: {
    getAuthDetails: (state: LoginState): LoginResponse | null => {
      if (state.loginResponse) {
        return state.loginResponse;
      }
      if (localStorage.getItem("accessToken")) {
        const token = localStorage.getItem("accessToken") as string;
        state.loginResponse = JSON.parse(token);
        return state.loginResponse;
      }
      return null;
    },
  },
  actions: {
    setAccessToken(token: LoginResponse | null): void {
      this.loginResponse = token;

      if (!token) {
        localStorage.clear();
      } else {
        localStorage.setItem("accessToken", JSON.stringify(token));
      }
    },
  },
});
