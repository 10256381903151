import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { RouteNames } from "./router/RouteNames.enum";
import { useLoginStore } from "./stores/login";
import router from "./router";

const apiUrl = process.env.VUE_APP_API_URL;

const apiClient: AxiosInstance = axios.create({
  baseURL: apiUrl,
});

const responseSuccessHandler = (requestConfig: AxiosRequestConfig) => {
  const loginStore = useLoginStore();
  const token = loginStore.getAuthDetails?.token;
  if (token && requestConfig.headers) {
    requestConfig.headers.Authorization = `Bearer ${token}`;
  }
  return requestConfig;
};

const responseErrorHandler = (error: AxiosError) => {
  if (error?.response?.status === 401) {
    router.push({ name: RouteNames.LOGIN });
  }
  return error;
};

// If the store has a bearer token, add it to the authorization request header.
apiClient.interceptors.request.use(
  (requestConfig) => responseSuccessHandler(requestConfig),
  (error) => responseErrorHandler(error)
);

export default apiClient;
