import { defineStore } from "pinia";

export type ConfirmationDialogState = {
  title: string;
  callback: (() => void) | null;
  message?: string;
  isVisible: boolean;
  showCancelButton: boolean;
  confirmationButtonText: string | null;
};

export type ConfirmationDialogContent = {
  title: string;
  callback: (() => void) | null;
  message?: string;
  showCancelButton: boolean;
  confirmationButtonText?: string | null;
};

export const useConfirmationDialogStore = defineStore("dialog/confirmation", {
  state: (): ConfirmationDialogState => ({
    title: "",
    callback: null,
    isVisible: false,
    message: "",
    showCancelButton: true,
    confirmationButtonText: null,
  }),
  actions: {
    showConfirmationDialog(model: ConfirmationDialogContent): void {
      this.title = model.title;
      this.callback = model.callback;
      this.isVisible = true;
      this.message = model.message ?? "";
      this.showCancelButton = model.showCancelButton;
      this.confirmationButtonText = model.confirmationButtonText ?? null;
    },
    hideConfirmationDialog(): void {
      this.$reset();
    },
  },
});
