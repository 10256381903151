
import { defineComponent } from "vue";
import Button from "@/components/UI/ButtonComponent.vue";
import { useErrorDialogStore } from "@/stores/error-dialog";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "ErrorDialogComponent",
  components: { Button },

  setup() {
    const errorDialogStore = useErrorDialogStore();

    const onClose = () => {
      errorDialogStore.hideErrorDialog();
    };

    const { isVisible, title, message } = storeToRefs(errorDialogStore);

    return { onClose, isVisible, title, message };
  },
});
