import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed w-full bg-color-white overflow-y-auto max-w-2xl max-h-[95vh] px-4 shadow-xl z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
}
const _hoisted_2 = { class: "inline-block w-full justify-between items-start rounded-t border-b p-5" }
const _hoisted_3 = { class: "inline-block w-full p-6" }
const _hoisted_4 = { class: "text-base leading-relaxed" }
const _hoisted_5 = { class: "flex justify-end w-full border-t p-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.message), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Button, {
            onClick: _ctx.onClose,
            buttonStyle: 'secondary'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("global.close")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]))
    : _createCommentVNode("", true)
}