import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "orderArticleDialogComponent rounded-lg fixed h-screen max-w-7xl max-h-[95vh] bg-color-white px-4 shadow-xl z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
}
const _hoisted_2 = { class: "w-full h-full flex flex-col" }
const _hoisted_3 = { class: "w-full flex justify-between items-center rounded-t border-b p-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-color-primary text-2xl" }
const _hoisted_6 = { class: "max-w-2xl" }
const _hoisted_7 = { class: "w-full p-4 text-left overflow-y-auto" }
const _hoisted_8 = { class: "mr-4" }
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = { class: "flex flex-1 flex-col mr-8" }
const _hoisted_11 = { class: "px-2 break-words" }
const _hoisted_12 = { class: "text-color-primary font-bold text break-words text-base mb-2" }
const _hoisted_13 = { class: "mb-4" }
const _hoisted_14 = {
  class: "mr-4 text-emphasis",
  for: "customerReference"
}
const _hoisted_15 = ["title"]
const _hoisted_16 = { class: "flex self-end space-between items-center flex-nowrap w-full border-t py-4 px-4" }
const _hoisted_17 = { class: "flex-1 text-left text-sm italic opacity-70" }
const _hoisted_18 = { class: "flex flex-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterOnPublicationType = _resolveComponent("FilterOnPublicationType")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.ArticleGalaxyLogo,
              alt: "Article Galaxy Logo"
            }, null, 8, _hoisted_4),
            _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.title), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_FilterOnPublicationType, { onOnSetFilterSelection: _ctx.onSetFilterSelection }, null, 8, ["onOnSetFilterSelection"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.publicationsExpanded, (publication) => {
              return (_openBlock(), _createElementBlock("div", {
                key: publication.id,
                class: "flex w-full py-4 border-b border-color-outline"
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _withDirectives(_createElementVNode("input", {
                    class: "cursor-pointer",
                    type: "checkbox",
                    "onUpdate:modelValue": ($event: any) => ((publication.selected) = $event)
                  }, null, 8, _hoisted_9), [
                    [_vModelCheckbox, publication.selected]
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("h5", _hoisted_12, _toDisplayString(publication.title), 1),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("span", null, _toDisplayString(publication.authors?.[0]), 1),
                      _createTextVNode(" - " + _toDisplayString(new Date(publication.date).toLocaleDateString()), 1)
                    ]),
                    _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("dialogs.orderArticle.referenceLabel")), 1),
                    _createVNode(_component_Input, {
                      class: "mr-4 flex-shrink w-3/4",
                      id: "customerReference",
                      modelValue: publication.customerReference,
                      "onUpdate:modelValue": ($event: any) => ((publication.customerReference) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ])
                ]),
                _createElementVNode("div", {
                  title: 
              _ctx.getCopyRightPriceTitle.find((p) => p.key === publication.id)?.text
            ,
                  class: "text-center w-40 h-fit py-2 px-4 text-color-primary-dark bg-color-background-light rounded"
                }, _toDisplayString(_ctx.getCopyRightPrice(publication.id)), 9, _hoisted_15)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("dialogs.orderArticle.priceExplanation")), 1),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_Button, {
                class: "mr-4",
                onClick: _ctx.onCancel,
                buttonStyle: 'secondary'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("global.cancel")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_Button, {
                onClick: _ctx.onProceed,
                buttonStyle: 'primary'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("dialogs.orderArticle.placeOrder")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}