import axios from "axios";
import { useErrorDialogStore } from "@/stores/error-dialog";
import { i18n } from "@/translations/translations";

export const useErrorDialog = (error: unknown): void => {
  const { t } = i18n.global;
  const dialogStore = useErrorDialogStore();

  const message = axios.isAxiosError(error)
    ? error.response?.data?.message ?? error.message
    : t("dialogs.error.generic.description");

  dialogStore.showErrorDialog({
    message,
    title: t("dialogs.error.generic.title"),
  });
};
